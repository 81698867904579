import React, { useEffect } from "react";
import Carousel from "./Carousel";
import { useInView } from "react-intersection-observer";
const Products = () => {
  const { ref: product, inView: inView2 } = useInView({threshold:0.8});
  useEffect(() => {
    const product = document.getElementById("products");
    if (inView2) {
      product.classList.add("opacity-100");
    }
  }, [inView2]);
  return (
    <div
      ref={product}
      id="products"
      className="opacity-0 duration-1000 ease-in-out w-[100%]  mb-[1rem] h-[15rem] sm:h-[25rem]  flex flex-col justify-center items-center"
    >
      <h1 className="text-2xl font-semibold text-navy p-2 ">PRODUCTS</h1>
      <div className=" w-[97%] sm:w-[95%] h-[15rem] sm:h-[25rem] shadow-md  bg-lightlime/50  flex flex-col justify-center rounded-lg text-center">
        <Carousel />
      </div>
    </div>
  );
};

export default Products;
