import React from "react";

const Navbar = ({title}) => {
  return (
    <div className="fixed z-10 font-title tracking-wide text-white bg-gradient-to-br from-teal/30 via-lightlime/30 to-freshlemon/20 h-[2.5rem] flex items-center  top-2 rounded-xl w-[96%] backdrop-blur-xl text-3xl font-bold  p-5">
      {title}
    </div>
  );
};

export default Navbar;
