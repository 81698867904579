export const data = [
    {
        discription:"T-Shirts With Your Fit And Style For Men,Women,Kids Choose From 50+ Shades Of Color Fabrics.",
        title: "OWN MANUFACTURING",
    },
    {
        discription:"Polo T Shirts Embroidery From Single Tee. Embroidery Best With Simple, Low Color Count Logos Or Artwork.",
        title: "LOGO EMBROIDERY",
    },
    {
        discription:"Direct To Film, Sublimation, DTG Print Effective For Small Orders With Gradient Designs Or More Than 8 Solid Colors.",
        title: "DIGITAL PRINTING",
    }
]

