import { AiOutlineWhatsApp } from "react-icons/ai";
import { Link } from "react-router-dom";
import React from "react";

const Whatsapp = () => {
    const number=7073441039
  return (
    <div className="fixed bottom-10 right-10">
      <Link to={"https://api.whatsapp.com/send?phone="+number} target="blank">
        <AiOutlineWhatsApp className="scale-[400%]  sm:scale-[400%] bg-teal/80 rounded-full p-1 shadow-2xl" />
      </Link>
    </div>
  );
};

export default Whatsapp;
