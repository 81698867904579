import React from "react";

const Footer = () => {
  return (
    <div className="w-full flex justify-center text-navy items-center h-[5rem]">
      © Fashionly
    </div>
  );
};

export default Footer;
