import design from '../images/design.jpeg'
import dtg from '../images/DTG-img.jpg'
import ht from '../images/Heat-transfer.jpg'
import ss from '../images/sampletshirt.jpg'
export const data = [
    {
        image:design,
        Title: "FREE DESIGN SERVICE",
        Description: "Say goodbye to struggles with online design tools and the need to learn new software. At Flex Fever, our expert human designers are just a message or call away. Designing your T-shirt is made simple with us. No more hassle—just quality designs tailored to your needs, effortlessly created by our skilled team. Experience the ease and convenience of personalized design services with Flex Fever today.",
    },
    {
        image:ss,
        Title: "SCREEN PRINTING",
        Description: "Silk Screen Printing is ideal for bulk/group orders and specific color matching across a wider range of products. Typically associated with t-shirt printing, it involves separating each color in a design and burning them onto individual fine-mesh screens. Ink is then transferred to the shirt through these screens. This method is popular due to its cost-effectiveness for printing large custom apparel orders with precision and quality.",
    },
    {
        image:dtg,
        Title: "DTG",
        Description: "Direct to garment printing is optimal for small runs, full-color photos, and quick turnaround times. This method entails printing a digital image directly onto a T-shirt or product, resembling the operation of a home inkjet printer. Special CMYK inks are blended to replicate the colors in your design, allowing for an unlimited range of colors. Consequently, digital printing is an excellent choice for reproducing photographs and intricate artwork with precision and vibrancy.",
    },
    {
        image:ht,
        Title: "HEAT TRANSFER",
        Description: "Offering a contemporary alternative to DTG printing, screen printing, or sublimation, DTF printing uses transfers to adorn both dark and light t-shirts on various materials like cotton, polyester, blends, leather, nylon, and more, without requiring costly equipment.It's evident that DTF printing is revolutionizing the industry, swiftly becoming a favored choice for textile printing over traditional methods. Flex Fever proudly boasts the finest DTF printer in Chennai and Madurai, Tamil Nadu, ensuring top-notch quality and service.",
    },
]

