import React,{useEffect} from "react";
import { FaCheck } from "react-icons/fa";
import {useInView} from "react-intersection-observer"
import { data } from "../data/deatils";
const Details = () => {
  const {ref:detail,inView:inView3}=useInView({threshold:0.4})
  useEffect(() => {
    const  details = document.getElementById("details");
    if (inView3) {
      details.classList.add("opacity-100");
    }

  }, [inView3]);
  return (
    <div ref={detail} id="details" className="opacity-0 duration-1000 ease-in-out details flex justify-around flex-wrap items-center w-full gap-5 p-5">
      {data.map((element, index) => (
        <div
          key={index}
          className="flex flex-col justify-center items-center gap-5 max-w-[20rem] "
        >
          <FaCheck size={150} color="#abd699" />
          <h1 className="font-semibold text-xl text-navy"> {element.title} </h1>
          <p className="text-center font-light"> {element.discription} </p>
        </div>
      ))}
    </div>
  );
};

export default Details;
