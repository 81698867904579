import React from "react";
import Wdwd from "../components/Wdwd";
import Products from "../components/Products";
import Title from "../components/Title";
import Navbar from "../components/Navbar";
import Second from "../components/Second";
import Footer from "../components/Footer";
import Details from "../components/Details";
import Whatsapp from "../components/Whatsapp";

const Home = () => {
  return (
    <div>
      <div className="relative top-0 w-full bg-[#c7ddcc] flex flex-col justify-center items-center gap-5 ">
        <Navbar title={"Flex Fever"}/>
        <Title title={"Flex Fever"}/>
        <Second />
        <Products />
        <Details />
        <Wdwd />
        <Footer />
        <Whatsapp />
      </div>
    </div>
  );
};

export default Home;
