import React,{useEffect} from 'react';
import {useInView} from "react-intersection-observer"
const Second = () => {
  const {ref:slide2,inView:inView1}=useInView({threshold:0.8})
  useEffect(() => {
    const  main1 = document.getElementById("main1");
    if (inView1) {
      main1.classList.add("opacity-100");
    }

  }, [inView1]);
    return (
        <div  className="w-full   h-[35rem]  text-wrap bg-mint text-center p-[2rem]">
        <div ref={slide2} id='main1' className="w-full  h-full opacity-0 duration-1000 ease-in-out flex flex-col justify-center gap-9 items-center">
          <h1 className="font-semibold  text-navy text-3xl  ">
            Printing your Dream Apparel
          </h1>
          <p className="font-light sm:p-5 text-black">
            We specialize in creating personalized t-shirts, hoodies, and other
            garments that reflect your unique style and personality. With our
            state-of-the-art technology and years of experience, we ensure that
            each print is vibrant, long-lasting, and tailored to your specific
            requirements. Whether you’re looking for branded merchandise for
            your business, event, or personal use, we’ve got you covered.
            Flex Fever was built on the idea that you shouldn’t need graphic
            design skills to create your own t-shirt. That’s why our designers
            makes it simple – if you can send a text, you can design your own
            t-shirt!
          </p>
        </div>
      </div>
      
    );
}

export default Second;
