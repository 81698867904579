import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import logo1 from "../images/blck.png";
const Title = ({ title }) => {
  const { ref: slide, inView } = useInView();
  useEffect(() => {
    const main = document.getElementById("main");
    const logo = document.getElementById("logo");
    if (inView) {
      main.classList.add("animate-slide");
      logo.classList.add("animate-bounce2");
      main.classList.add("opacity-100");
    }
  }, [inView]);
  return (
    <div
      id="main"
      ref={slide}
      className=" flex opacity-0 duration-[2000ms] justify-around items-center w-full h-[30rem] relative top-0 bg-gradient-to-tr from-teal via-lightlime to-lightlime shadow-lg rounded-b-3xl"
    >
      <div className="flex flex-col  items-center  car:items-start md:items-center p-5 bg- w-full ">
        <h1 className="font-title text-[5rem] md:-translate-x-12 text-navy sm:text-[7rem] ">
          {title}
        </h1>
        <p className=" text-[#F3F6FA] font-proto  sm:text-xl md:text-2xl text-lg sm:text-start text-balance text-center">
          Wear Your Imagination! <br /> Unleash Your Personalized Style <br />{" "}
          with Our T-Shirt Printing Services{" "}
        </p>
      </div>
      <div className="hidden car:w-full car:flex justify-center items-center  h-[50%]">
        <img
          src={logo1}
          id="logo"
          alt="logo"
          className="object-cover animate-bounce2  car:w-[15rem] md:w-[19rem] p-5"
        />
      </div>
      {/* </div> */}
    </div>
    // </div>
  );
};

export default Title;
