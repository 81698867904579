import React, { useEffect } from "react";
import { data } from "../data/service";
import { useInView } from "react-intersection-observer";

const Wdwd = () => {
  const { ref: wded, inView: inView4 } = useInView({ threshold: 0.2 });
  useEffect(() => {
    const ele = document.getElementById("wded");
    if (inView4) {
      ele.classList.add("opacity-100");
    }
  }, [inView4]);
  return (
    <div
      ref={wded}
      id="wded"
      className="opacity-0 ease-in-out duration-1000  w-[97%] sm:w-[95%] h-max shadow-md  bg-lightlime/50 p-[1rem] flex flex-col justify-center rounded-lg text-center"
    >
      <h1 className="text-2xl font-semibold text-navy p-5 mb-5">
        WHAT DO WE DO
      </h1>
      <div className="flex justify-around items-center flex-wrap  w-full gap-4 sm:grid place-content-center box1:grid-cols-2 md:grid place-items-center box:grid-cols-4">
        {data.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center md:justify-start h-[40rem]  gap-3 sm:gap-5  max-w-[20rem] rounded-lg"
          >
            <img
              src={item.image}
              alt={" "}
              className="  w-[17.5rem] object-cover rounded-lg"
            />
            <div className="flex flex-col gap-5 w-[95%]">
              <h1 className="text-xl text-navy font-normal">{item.Title}</h1>
              <p className="texta text-justify text-wrap  font-light overflow-hidden">
                {item.Description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Wdwd;
