import { data } from "../data/products";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Carousel = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1272,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="sm:p-[2rem] p-[1.5rem]">
      <Slider {...settings}>
        {data.map((card, index) => (
          <div
            key={index}
            className="p-2 flex justify-center items-center max-w-[220px] min-h-[220px]"
          >
            <img
              src={card.image}
              alt=""
              className="rounded-md object-fill car:max-w-[220px] max-h-[215px]"
            />
            <p className="text-[0.6rem]  text-center text-balance sm:text-[1.2rem] font-proto p-2">
              {card.Title}
            </p>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default Carousel;
