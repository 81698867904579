import hood from "../images/hood.jpeg"
import varsity from "../images/Var.jpeg"
import polo from "../images/polo1.jpeg"
import round from "../images/round.jpeg"
import sweat from "../images/sweat.jpeg"
import over from "../images/oversized.jpeg"
export const data = [
    {
        image:round,
        Title: "ROUND NECK",
    },
    {
        image:polo,
        Title: "POLO T-SHIRT",
    },
    {
        image:sweat,
        Title: "SWEATSHIRT",
    },
    {
        image:hood,
        Title: "HOODIES",
    },
    {
        image:varsity,
        Title: "VARSITY JACKET",
    },
    {
        image:over,
        Title: "OVERSIZED",
    },
   
]

